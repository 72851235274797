import React from 'react';
import Subtitle from '../subtitle';
import { SectionTitleWrap } from './section-title.style';

interface SectionTitleProps {
  title?: string;
  subtitle?: string;
}

const SectionTitle = ({ title, subtitle, ...props }: SectionTitleProps) => {
  return (
    <SectionTitleWrap {...props}>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {title && <h3 dangerouslySetInnerHTML={{ __html: title }} />}
    </SectionTitleWrap>
  );
};

SectionTitle.defaultProps = {
  align: 'center',
};
export default SectionTitle;
