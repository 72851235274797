import styled, { css } from 'styled-components';
import { device } from 'theme';

export const SectionTitleWrap = styled.div`
  text-align: ${(props) => props.align};
  margin-bottom: ${(props) => props.mb};
  margin-top: ${(props) => props.mt};
  color: ${(props) => props.color};
  ${(props) =>
    props.responsive &&
    css`
      ${(props) =>
    props.responsive.medium &&
        css`
          @media ${device.medium} {
            margin-bottom: ${(props) => props.responsive.medium.mb};
            text-align: ${(props) => props.responsive.medium.align};
          }
        `}
      ${(props) =>
    props.responsive.small &&
        css`
          @media ${device.small} {
            margin-bottom: ${(props) => props.responsive.small.mb};
            text-align: ${(props) => props.responsive.small.align};
          }
        `}
    `}
  h3 {
    color: ${(props) => props.color};
    mark {
      color: ${(props) => props.theme.colors.themeColor};
    }
  }
`;
